import React from "react";

export default ({ color }) => (
  <svg width="69" height="70" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3586_3180)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M41.3561 53.9229C39.1446 52.196 39.5548 50.8552 40.227 49.6454C41.538 47.1614 41.952 44.7464 41.331 42.4694C40.986 41.2274 41.124 39.9854 41.607 38.7434C43.47 34.1894 41.883 29.2214 41.814 29.0144C41.676 28.6004 37.4139 28.3623 36.9999 28.5003C36.5854 28.3623 32.0849 28.6 31.9469 29.014C31.8779 29.221 30.2909 34.189 32.1539 38.743C32.6369 39.916 32.7749 41.227 32.4299 42.469C31.8089 44.746 32.2229 47.161 33.5339 49.645C34.1383 50.9208 34.6119 52.262 32.4112 53.9229H31.2568C29.3248 53.9229 27.7378 55.5099 27.7378 57.4419V61.7199H45.9538V57.4419C45.9538 55.5099 44.3668 53.9229 42.4348 53.9229H41.3561ZM41.0341 55.5099C41.0412 55.5102 41.0482 55.5104 41.055 55.5104C41.0682 55.5104 41.0814 55.5102 41.0943 55.5099H42.3658C43.4698 55.5099 44.2978 56.4069 44.2978 57.4419V60.1329H29.3248V57.4419C29.3248 56.3379 30.2218 55.5099 31.2568 55.5099H32.6205C32.6259 55.51 32.6314 55.51 32.6369 55.51C32.6515 55.51 32.6669 55.51 32.683 55.5099H41.0341Z" fill={color} />
      <path d="M52.3019 70.0008H21.3899V63.9978C21.3899 61.8588 23.1149 60.1338 25.2539 60.1338H48.3689C50.5079 60.1338 52.2329 61.8588 52.2329 63.9978V70.0008H52.3019ZM22.9769 68.4138H50.7149V64.0668C50.7149 62.8248 49.6799 61.7898 48.4379 61.7898H25.2539C24.0119 61.7898 22.9769 62.8248 22.9769 64.0668V68.4138Z" fill={color} />
      <rect x="22" y="62" width="29" height="8" fill={color} />
      <rect x="24" y="61" width="26" height="8" fill={color} />
      <path d="M43.1048 29.5132C42.8288 29.5132 42.5528 29.3752 42.4148 29.0993C42.4148 29.0993 41.3108 26.8913 39.5858 25.0973C38.7578 24.2693 37.7228 23.7862 36.5498 23.7862C35.3768 23.7862 34.3418 24.2693 33.5138 25.0973C31.7888 26.8913 30.6848 29.0993 30.6848 29.0993C30.5468 29.3752 30.2708 29.5132 29.9258 29.5132C29.5808 29.5132 29.3048 29.3062 29.2358 29.0303C27.9938 26.0633 27.3038 21.7162 27.0278 18.6112C26.8208 16.9552 27.7868 15.3683 29.3048 14.7473C30.1328 14.4023 31.0298 13.9192 31.7888 13.5052C32.6858 12.9532 33.0998 11.9183 32.7548 11.0212C32.2718 9.64125 32.2028 8.19225 32.2028 7.22625C32.2028 5.57025 33.1688 4.05225 34.6868 3.43125C34.9628 3.29325 35.2388 3.22425 35.5148 3.15525C36.2048 2.94825 37.0328 2.94825 37.7228 3.15525C37.9988 3.22425 38.2748 3.36225 38.5508 3.43125C39.9998 4.12125 40.9658 5.57025 41.0348 7.22625C41.0348 8.19225 40.9658 9.64125 40.4828 11.0212C40.1378 11.9183 40.5518 13.0223 41.4488 13.5052C42.2078 13.9882 43.1048 14.4023 43.9328 14.7473C45.4508 15.4373 46.3478 16.9552 46.2098 18.6112C45.8648 21.7162 45.2438 26.0633 44.0018 29.0303C43.7258 29.3062 43.4498 29.5132 43.1048 29.5132C43.1738 29.5132 43.1738 29.5132 43.1048 29.5132Z" fill={color} />
    </g>
    <path d="M25.8965 46.5571C27.7097 45.7382 28.5158 43.6044 27.6969 41.7912C26.878 39.978 24.7442 39.172 22.931 39.9909C21.1178 40.8098 20.3118 42.9435 21.1307 44.7567C21.9496 46.5699 24.0833 47.376 25.8965 46.5571Z" fill="#333333" />
    <path d="M27.7819 43.9287C29.2654 43.2587 29.9249 41.5129 29.2549 40.0294C28.5849 38.5458 26.8391 37.8864 25.3556 38.5564C23.8721 39.2264 23.2126 40.9722 23.8826 42.4557C24.5526 43.9392 26.2984 44.5987 27.7819 43.9287Z" fill="#FFB000" />
    <path d="M53.5258 39.3308C52.7952 37.4561 50.6832 36.5287 48.8085 37.2593C46.9338 37.9899 46.0063 40.1019 46.7369 41.9766C47.4675 43.8513 49.5796 44.7788 51.4543 44.0482C53.329 43.3176 54.2564 41.2055 53.5258 39.3308Z" fill="#333333" />
    <path d="M50.9714 37.2875C50.3737 35.7536 48.6456 34.9948 47.1118 35.5926C45.5779 36.1903 44.8191 37.9184 45.4169 39.4522C46.0147 40.9861 47.7427 41.7449 49.2765 41.1471C50.8104 40.5493 51.5692 38.8213 50.9714 37.2875Z" fill="#F76E9C" />
    <path d="M23.1692 4.01247C21.8166 5.39506 21.8409 7.61235 23.2235 8.96493C24.6061 10.3175 26.8233 10.2932 28.1759 8.91061C29.5285 7.52803 29.5042 5.31074 28.1216 3.95816C26.739 2.60557 24.5217 2.62989 23.1692 4.01247Z" fill="#333333" />
    <path d="M22.3907 7.06019C21.2841 8.19139 21.304 10.0055 22.4352 11.1122C23.5664 12.2189 25.3805 12.199 26.4872 11.0678C27.5938 9.93655 27.5739 8.12241 26.4427 7.01575C25.3115 5.90909 23.4974 5.92898 22.3907 7.06019Z" fill="#73A77B" />
    <defs>
      <clipPath id="clip0_3586_3180">
        <rect width="69" height="69" fill="white" transform="translate(0 1)" />
      </clipPath>
    </defs>
  </svg>

);
